import React, { useCallback } from 'react'
import { Form, Button, Input, Typography } from 'antd'
import useLoadableState from '../hooks/useLoadableState';


interface EmailFormProps {
  successCallback: Function
}

export const EmailForm = ({ successCallback }: EmailFormProps) => {

  const [[isLoading, error], { setError, setIsLoading, setLoadedValueWithoutError }] = useLoadableState()

  const handleSubmit = useCallback(async (values: any) => {
    setIsLoading(true)
    try {
      const res = await fetch('/api/newsletter/subscribe', {
        body: JSON.stringify({
          email: values.email
        }),
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST'
      })

      if (res.ok) {
        setLoadedValueWithoutError(
          await res.json()
        )
        successCallback()
      }
      else {
        setError(
          await res.json()
        )
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(
          error
        )
      }
    }

    setIsLoading(false)

  }, [setIsLoading, successCallback])

  return (
    <Form
      layout="vertical" size="large"
      onFinish={handleSubmit}
      onChange={() => setError(undefined)}
    >
      <Form.Item
        label="Email"
        name="email"
        initialValue=''
        rules={[
          {
            type: 'email',
            message: "Ogiltigt epost-adress",
          },
          {
            required: true,
            message: 'Fyll i din epost',
          },
        ]}
        extra={error && <Typography.Text type="danger">Epost-adressen är redan registrerad</Typography.Text>}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Registrera
        </Button>
      </Form.Item>
    </Form>
  );
}
