import classNames from 'classnames'
import { AnyChildren } from '../types'

interface GridCellProps {
  children: AnyChildren
}

export default function GridCell({ children }: GridCellProps) {
  const className = classNames(
    "Grid_Cell", {
  })

  return (
    <div className={className}>
      {children}
    </div>
  )
}