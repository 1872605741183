import { useEffect, useRef } from "react";

interface VideoPlayerProps {
  videoUrl: string,
  posterUrl: string,
  pauseOnClickOutside: boolean
}

export default function VideoPlayer({ videoUrl, posterUrl, pauseOnClickOutside }: VideoPlayerProps) {
  const containerElRef = useRef<HTMLDivElement>(null)
  const videoElRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (videoElRef.current && pauseOnClickOutside) {
      const handleBodyClick = function (e: MouseEvent) {
        const clickedOutsideMenu = !(videoElRef.current as HTMLVideoElement).contains(e.target as HTMLElement)
        if (clickedOutsideMenu) {
          (videoElRef.current as HTMLVideoElement).pause()
        }
      }

      document.body.addEventListener("click", handleBodyClick)

      return function cleanup() {
        document.body.removeEventListener("click", handleBodyClick)
      }
    }
  }, [videoElRef.current, pauseOnClickOutside])

  return (
    <div
      ref={containerElRef}
      style={{
        position: "relative",
        border: "1px solid #00000066",
        boxShadow: "0px 1px 20px #00000022, -1px 4px 6px #00000055",
        borderRadius: 3,
        overflow: "hidden",
      }}>
      <video
        ref={videoElRef}
        src={videoUrl}
        poster={posterUrl || undefined}
        controls
        preload="none"
        style={{
          width: "100%",
          objectFit: "cover",
          display: "block",
        }}
      />
      <div
        style={{

        }}
      />
    </div>
  )
}