import { Button, Form, Input, Tooltip } from "antd"
import { InputProps } from "antd/lib/input"
import { useRouter } from "next/dist/client/router"
import { useCallback, useContext, useState } from "react"
import CoursesApiClientContext from "../contexts/CoursesApiClientContext"
import { CoursesApiRequestError } from "../lib/coursesApi/CoursesApiClient"
import { exactLengthFormItemRuleFactory, requiredFormItemRule } from "../forms/formItemRules"
import useBooleanState from "../hooks/useBooleanState"

interface CodeInputProps extends InputProps {
  hasError?: boolean
}

function CodeInput({ hasError, ...InputProps }: CodeInputProps) {
  return (
    <label
      style={{
        display: "block",
        padding: "5px",
        textAlign: "center",
        boxShadow: "0 5px 15px #00000011",
        border: "1px solid",
        borderColor: hasError ? "#ff4d4f" : "#99999955",
        backgroundColor: "white",
        color: hasError ? "#ff4d4f" : "inherit",
      }}
    >
      <Input
        // value={value}
        // onChange={handleOnChange}
        {...InputProps}
        placeholder="----"
        autoComplete="off"

        style={{
          lineHeight: "1em",
          padding: "10px 0 10px 20px",
          fontSize: "30px",
          letterSpacing: "20px",
          // textTransform: "uppercase",
          fontFamily: "monospace",
          width: "174px",
          border: "none",
          color: "inherit"
        }}
      />
    </label>
  )
}

interface CourseParticipationTicketBundleCodeFormProps {
  children: string
}

export default function CompanyCourseParticipationTicketBundleCodeForm({ children }: CourseParticipationTicketBundleCodeFormProps) {

  const [form] = Form.useForm()
  const [isValid, setIsValid, setIsNotValid] = useBooleanState(false)
  const [error, setError] = useState<CoursesApiRequestError>()
  const [isLoading, setIsLoading, setIsNotLoading] = useBooleanState(false)
  const coursesApiClient = useContext(CoursesApiClientContext)

  const router = useRouter()

  const handleSubmitCode = useCallback(async ({ code }) => {
    setIsLoading()
    try {
      const bundle = await coursesApiClient.getCompanyCourseParticipationTicketBundleTokenByCode(code)
      setError(undefined)

      router.push({
        query: {
          ...router.query,
          courseParticipationTicketBundleToken: bundle.token
        }
      })
    } catch (error) {
      if (error instanceof CoursesApiRequestError) {
        setError(error)
      }
    }

    setIsNotLoading()

  }, [coursesApiClient, router, setIsLoading, setIsNotLoading, setError])

  return (
    <>
      <Tooltip title={({
        "DOES_NOT_EXIST": "Koden är inte giltig"
      } as { [key: string]: string })[error?.payload.error_code] || "Okänt fel, testa igen om en stund"} visible={error !== undefined} color="red">
        <Form
          form={form}
          onFinish={handleSubmitCode}
          onChange={async () => {
            setError(undefined)
            try {
              await form.validateFields()
              setIsValid()
            } catch (error) {
              setIsNotValid()
            }
          }}
        >
          <Form.Item
            name="code"
            help={""}
            normalize={(value: string) => value.toUpperCase().slice(0, 4)}
            hasFeedback={false}
            rules={[
              requiredFormItemRule,
              exactLengthFormItemRuleFactory(4)
            ]}
          >
            <CodeInput
              hasError={error !== undefined}
            />
          </Form.Item>
          <Form.Item style={{ marginTop: -48 + 8, marginBottom: 0 }}>
            <Button
              htmlType="submit"
              size="large"
              block
              type="primary"
              disabled={!isValid}
              loading={isLoading}
            >
              {children}
            </Button>
          </Form.Item>
        </Form>
      </Tooltip>
    </>
  )
}