import { Rule } from "antd/lib/form";

export const requiredFormItemRule: Rule = {
  required: true,
  message: "Obligatoriskt fält"
}

export const notEmptyFormItemRule: Rule = {
  validator(_, value) {
    if (!value || value.length === 0) {
      return Promise.reject()
    }

    return Promise.resolve()
  },
  message: "Välj minst ett alternativ"
}

export const minLengthFormItemRuleFactory = (minLength: number): Rule => (
  {
    min: minLength,
    message: `Minst ${minLength} antal tecken`
  }
)

export const exactLengthFormItemRuleFactory = (length: number): Rule => (
  {
    len: length,
    message: `Exakt ${length} antal tecken`
  }
)

export const onlyDigitsFormItemRule: Rule = {
  pattern: /\d+/,
  message: "Endast siffror"
}