import classNames from 'classnames'
import { AnyChildren } from '../types'

import styles from './Grid.module.css'

export enum GAPS {
  NONE = "none",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  XLARGE = "xlarge",
  MEGA = "mega"
}

export enum LAYOUTS {
  TWO_COLUMS = "twoColumns",
  TWO_COLUMNS_FLEXIBLE = "twoColumnsFlexible",
}

interface GridProps {
  gap: GAPS,
  layout: LAYOUTS,
  children: AnyChildren
}

export default function Grid({ gap, layout, children }: GridProps) {
  const className = classNames(
    styles.Grid, {
    [styles[`--gap-${gap}`]]: !!gap,
    [styles[`--layout-${layout}`]]: !!layout,
  })

  return (
    <div className={className}>
      {children}
    </div>
  )
}