import { Button, Modal } from 'antd'
import { PropsWithChildren, useCallback, useState } from "react"

import { EmailForm } from './EmailForm'
import NewsletterDialogInfoContent from '../content/partials/newsletter-signup/dialog-info.mdx'
import NewsletterSuccessTextContent from '../content/partials/newsletter-signup/success-message.mdx'
import useBooleanState from '../hooks/useBooleanState'

export default function NewsletterSignupModalButton({ children }: PropsWithChildren<{}>) {
  const [isOpen, open, close] = useBooleanState(false)
  const [hasCompletedSignedUp, completedSignedUp, resetCompletedSignedUp] = useBooleanState(false)

  const reset = useCallback(() => {
    close()
    resetCompletedSignedUp()
  }, [close, resetCompletedSignedUp])

  return (
    <>
      <Button type="primary" onClick={open}>{children}</Button>
      <Modal
        visible={isOpen}
        onCancel={reset}
        footer={null}
      >
        <NewsletterDialogInfoContent />
        {!hasCompletedSignedUp ?
          <EmailForm successCallback={completedSignedUp} /> :
          <NewsletterSuccessTextContent />}

      </Modal>
    </>
  )
}
