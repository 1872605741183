
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from "../../layouts/DefaultLayout";
import Grid from "../../components/Grid/Grid";
import GridCell from "../../components/Grid/GridCell";
import VideoPlayer from "../../components/VideoPlayer";
import NewsletterSignupModalButton from "../../containers/NewsletterSignupModalButton";
import NextImage from "next/image";
import CompanyCourseParticipationTicketBundleCodeForm from "../../containers/CompanyCourseParticipationTicketBundleCodeForm";

import { Row, Col, Card, Divider } from "antd";
import { Tag } from "antd";
import CardMeta from "antd/lib/card/Meta";
import {
  DownOutlined,
  ShoppingOutlined,
  MessageOutlined,
  BulbOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { createWebappImgixUrl } from "../../utils/imgix";
import PageMeta from "../../components/PageMeta";

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Section = makeShortcode("Section");
const LargePrimaryLinkButton = makeShortcode("LargePrimaryLinkButton");
const ColorSection = makeShortcode("ColorSection");
const PreTitle = makeShortcode("PreTitle");
const LightSection = makeShortcode("LightSection");
const Image = makeShortcode("Image");
const Fragment = makeShortcode("Fragment");
const Spacing = makeShortcode("Spacing");
const layoutProps = {
  
};
const MDXLayout = Layout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <PageMeta title={["Webbutbildning - Entreprenadbranschen"]} description="Vi levererar webbutbildningar inom Arbete på väg och ADR för Entreprenadbranschen." mdxType="PageMeta" />
    <Section width="wide" justify="left" horizontalSpacing="medium" style={{
      marginTop: "calc(-1 * var(--topbar-height))",
      paddingTop: "calc(var(--topbar-height) + 40px)",
      height: 500
    }} hero innerHeroStyle={{
      backgroundImage: `url(${createWebappImgixUrl("/image/hero-dan-lightblue-center-4000x1200R-50.jpg", {
        width: 2000,
        quality: 5,
        dpr: 2
      })})`,
      backgroundSize: "2000px",
      backgroundPosition: "center bottom",
      backgroundRepeat: "no-repeat"
    }} mdxType="Section">
      <Row mdxType="Row">
  <Col span={12} mdxType="Col">
    <h1 style={{
            color: "#18344c"
          }}>Utbildning i Arbete på väg och ADR 1.3</h1>
    <p style={{}}>
      Webbutbildning som ger rätt kunskap och synkar ditt intyg med ID06.
      Beställ nu och kom igång direkt!
    </p>
    <div style={{
            marginTop: 24
          }}>
      <LargePrimaryLinkButton href="/utbildningar" buttonProps={{
              shape: "round"
            }} mdxType="LargePrimaryLinkButton">
        Se våra utbildningar
      </LargePrimaryLinkButton>
    </div>
  </Col>
      </Row>
    </Section>
    <ColorSection width="slender" verticalSpacing="large" mdxType="ColorSection">
      <Row gutter={32} align="middle" mdxType="Row">
  <Col xs={24} sm={12} mdxType="Col">
    <h2>Har du en utbildningskod?</h2>
  </Col>
  <Col xs={24} sm={12} mdxType="Col">
          <CompanyCourseParticipationTicketBundleCodeForm mdxType="CompanyCourseParticipationTicketBundleCodeForm">
  Aktivera utbildning
          </CompanyCourseParticipationTicketBundleCodeForm>
  </Col>
      </Row>
    </ColorSection>
    <Section width="wider" verticalSpacing="large" topMargin="large" mdxType="Section">
  <PreTitle mdxType="PreTitle">Utbildnings- och intygsadministration <Tag color="#18344c" style={{
          verticalaline: "middle"
        }} mdxType="Tag">Nyhet!</Tag></PreTitle>
  <h2>Underlätta administrationen</h2>
  <Row gutter={[32, 32]} align="middle" mdxType="Row">
    <Col xs={24} sm={12} mdxType="Col">
      <NextImage src="logo/assistenten-logo-vertical.png" width={420} height={297 * (420 / 1115)} quality={10} mdxType="NextImage" />
    </Col>
    <Col xs={24} sm={12} mdxType="Col">
      <h3>Nytt webbaserat administrationsverktyg</h3>
      <p>
        Med Entreprenadassisten skapar ni ett systematiskt arbetssätt för utbildningar, certifieringar,
        hälsoundersökningar och körtillstånd. Hela kedjan från överblick till genomförande. Skapa digitala
        körtillstånd som signeras med BankID.
        Entreprenadassistenten underlättar helt enkelt administrationen, säkerställer överlämning och gör att ni undviker dyra misstag.    
      </p>
      <p>
      <a href="https://www.entreprenadassistenten.se/" native external>
      Entreprenadassistenten >
      </a>
      </p>
    </Col>
  </Row>
    </Section>
    <LightSection width="wider" justify="center" verticalSpacing="xlarge" topMargin="" mdxType="LightSection">
      <h2 style={{
        color: "#18344c"
      }}>Populära utbildningar!</h2>
      <Row align="middle" gutter={[16, 16]} mdxType="Row">
  <Col xs={24} sm={12} md={8} mdxType="Col">
    <a href="/utbildningar/arbete-pa-vag-grundutbildning-entreprenor">
      <Card cover={<Image imagePath="/illustration/traktor-tma-2-3864-2460-25-april-21-50.jpg" alt="Illustration traktor TMA skydd" width={400} ar="4:2" mdxType="Image" />} mdxType="Card">
        <CardMeta title="Arbete på väg steg 1 - Entreprenör" description={<Fragment mdxType="Fragment">
              <p>Ger intyg för APV steg 1.1, 1.2 och 1.3 samt APV nivå 1&2.</p>
              <div style={{
                  fontSize: 30,
                  fontWeight: 600,
                  lineHeight: 1.35,
                  color: "#18344c"
                }}>2 195 kr</div>
              {
                  /* <p style={{ textDecoration: "line-through" }}>Ordinarie pris: 2 195 kr</p> */
                }
            </Fragment>} mdxType="CardMeta" />
      </Card>
    </a>
  </Col>
  <Col xs={24} sm={12} md={8} mdxType="Col">
    <a href="/utbildningar/arbete-pa-vag-grundutbildning-konsult">
      <Card cover={<Image imagePath="/illustration/konsult-3863-2460-24-april-21Rityta 3-50.jpg" alt="Illustration mättekniker" width={400} ar="4:2" mdxType="Image" />} mdxType="Card">
        <CardMeta title="Arbete på väg steg 1 - Konsult" description={<Fragment mdxType="Fragment">
              <p>Ger intyg för APV steg 1.1, 1.2 och 1.3 samt APV nivå 1&2.</p>
              <div style={{
                  fontSize: 30,
                  fontWeight: 600,
                  lineHeight: 1.35,
                  color: "#18344c"
                }}>2 195 kr</div>
              {
                  /* <p style={{ textDecoration: "line-through" }}>Ordinarie pris: 2 195 kr</p> */
                }
            </Fragment>} mdxType="CardMeta" />
      </Card>
    </a>
  </Col>
  <Col xs={24} sm={12} md={8} mdxType="Col">
    <a href="/utbildningar/adr-1-3-bygg-och-anlaggning">
      <Card cover={<Image imagePath="/illustration/ADR-adr-gubbe-bil-2-100.jpg" alt="Illustration transport av farligt gods" width={400} ar="4:2" mdxType="Image" />} mdxType="Card">
        <CardMeta title="ADR 1.3 - Bygg och anläggning" description={<Fragment mdxType="Fragment">
              <p>Ger intyg för ADR 1.3 - grundkunskaper om transport av farligt gods.</p>
              <div style={{
                  fontSize: 30,
                  fontWeight: 600,
                  lineHeight: 1.35,
                  color: "#18344c"
                }}>1 495 kr</div>
              {
                  /* <p style={{ textDecoration: "line-through" }}>Ordinarie pris: 1 495 kr</p> */
                }
            </Fragment>} mdxType="CardMeta" />
      </Card>
    </a>
  </Col>
      </Row>
    </LightSection>
    <Section width="wide" topMargin="large" verticalSpacing="large" justify="center" mdxType="Section">
  <h2>4 goda skäl att utbilda sig hos oss!</h2>
  <Row style={{
        marginTop: 24
      }} mdxType="Row">
    <Col xs={24} offset={0} mdxType="Col">
      <VideoPlayer videoUrl="https://d2aohbv57atykn.cloudfront.net/why-choose-with-dave.mp4" posterUrl={createWebappImgixUrl("/image/why-choose-with-dave.jpg", {
            width: 766,
            fit: "crop",
            aspectRatio: "766:430"
          })} mdxType="VideoPlayer" />
    </Col>
  </Row>
  <Row gutter={16} style={{
        marginTop: 24
      }} mdxType="Row">
    <Col xs={24} sm={6} mdxType="Col">
      <p style={{
            height: 80,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}>
        <BulbOutlined style={{
              color: "green",
              fontSize: 50
            }} mdxType="BulbOutlined" />
      </p>
      <p>
        <b>Kvalité</b> – Hos oss innebär godkänt slutprov rätt kunskap
      </p>
    </Col>
    <Col xs={24} sm={6} mdxType="Col">
      <p style={{
            height: 80,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}>
        <NextImage src="/logo/bankid_high_rgb.png" alt="BankID logga" width={64} height={64 * (1470 / 1548)} quality={10} mdxType="NextImage" />
      </p>
      <p>
        <b>BankID</b> – Hos oss har du en enkel och säker inloggning
      </p>
    </Col>
    <Col xs={24} sm={6} mdxType="Col">
      <p style={{
            height: 80,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}>
        <NextImage src="/logo/id06-2024.png" alt="ID06 logga" width={128} height={32} quality={10} mdxType="NextImage" />
      </p>
      <p>
        <b>ID06</b> – Hos oss registreras ditt intyg automatiskt
      </p>
    </Col>
    <Col xs={24} sm={6} mdxType="Col">
      <p style={{
            height: 80,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}>
        <PieChartOutlined style={{
              color: "green",
              fontSize: 50
            }} mdxType="PieChartOutlined" />
      </p>
      <p>
        <b>Uppföljning</b> – Hos oss får du som är administratör en oslagbar
        överblick
      </p>
    </Col>
  </Row>
    </Section>
    <LightSection width="wider" justify="center" verticalSpacing="xlarge" topMargin="" mdxType="LightSection">
      <PreTitle mdxType="PreTitle">Instruktioner</PreTitle>
      <h2>{`Såhär går det till`}</h2>
      <Row align="middle" gutter={[16, 16]} style={{
        marginTop: "2em"
      }} mdxType="Row">
  <Col xs={24} sm={12} md={8} mdxType="Col">
    <div style={{
            marginBottom: "1em"
          }}>
      <VideoPlayer videoUrl="https://d2aohbv57atykn.cloudfront.net/hur-bestaller-jag-utbildning-final.mp4" posterUrl={createWebappImgixUrl("/image/blur-bestaller-utbildning-50.jpg", {
              width: 800,
              fit: "crop",
              aspectRatio: "800:500"
            })} mdxType="VideoPlayer" />
    </div>
    <h4>Beställ</h4>
    <p>I den här videon förklarar vi hur du går tillväga när du beställer utbildning.</p>
  </Col>
  <Col xs={24} sm={12} md={8} mdxType="Col">
    <div style={{
            marginBottom: "1em"
          }}>
      <VideoPlayer videoUrl="https://d2aohbv57atykn.cloudfront.net/hur-genomfor-jag-min-utbildning-final.mp4" posterUrl={createWebappImgixUrl("/image/blur-genomfor-utbildning-50.jpg", {
              width: 800,
              fit: "crop",
              aspectRatio: "800:500"
            })} mdxType="VideoPlayer" />
    </div>
    <h4>Genomför</h4>
    <p>I den här videon förklarar vi hur du aktiverar, kommer igång och genomför din utbildning.</p>
  </Col>
  <Col xs={24} sm={12} md={8} mdxType="Col">
    <div style={{
            marginBottom: "1em"
          }}>
      <VideoPlayer videoUrl="https://d2aohbv57atykn.cloudfront.net/hur-kan-jag-folja-upp-deltagarnas-utbildningar-final.mp4" posterUrl={createWebappImgixUrl("/image/blur-folja-upp-utbildning-50.jpg", {
              width: 800,
              fit: "crop",
              aspectRatio: "800:500"
            })} mdxType="VideoPlayer" />
    </div>
    <h4>Följ upp</h4>
    <p>I den här videon förklarar vi hur du följer upp deltagare, skriver ut intyg, med mera.</p>
  </Col>
      </Row>
      <div style={{
        marginTop: "2em"
      }}>
Du kan läsa mer under   
        <a href="/vanliga-fragor"> vanliga frågor</a> eller <a href="/kontakt">
  kontakta oss
        </a>.
      </div>
    </LightSection>
    <Section width="slender" verticalSpacing="large" mdxType="Section">
  <PreTitle mdxType="PreTitle">Samarbete</PreTitle>
  <p style={{
        marginTop: "2em"
      }}>
    <NextImage src="/logo/afry-Logo.png" alt="Logotyp för AFRY - ÅF Pöyry" width={500} height={500 * 1471 / 5171} quality={10} mdxType="NextImage" />
  </p>
  <h2>Unik utbildning skapad ihop med AFRY</h2>
  <p>
    När AFRY tyckte att det saknades en Arbete på väg-utbildning anpassad för
    konsulter, vände de sig till oss på Entreprenadakademin för hjälp. Uppdraget
    blev starten på ett samarbete där vi tillsammans tagit fram en utbildning
    som är skräddarsydd, inte enbart för AFRY:s egen personal, utan för alla i
    konsultbranschen som arbetar på eller vid sidan av vägen.
  </p>
  <p>
    APV-kurser riktar sig vanligtvis till entreprenörer, men den här unika
    utbildningen tar i stället avstamp i en konsults vardag. Baserat på
    intervjuer med personal från AFRY har innehållet anpassats till målgruppen
    och kursdeltagaren får också ta del av exempel ur verkligheten.
  </p>
  <p>
    Utbildningen{" "}
    <a href="/utbildningar/arbete-pa-vag-grundutbildning-konsult">
      Arbete på väg steg 1 - Grundutbildning Konsult
    </a>{" "}
    är en komplett paketlösning som ger kunskap för hela Arbete på väg steg 1
    (1.1, 1.2, 1.3) samt nivå 1 och 2, enligt Trafikverkets riktlinjer och
    kompetenskrav.
  </p>
  <figure>
    <blockquote>
      Ett kul och givande samarbete för att stärka vår kompetens och
      kunskapsnivå inom området. Samarbetet har präglats av öppenhet och
      nyfikenhet och det, tillsammans med Entreprenadakademins långa erfarenhet
      inom området, har lett till en mycket bra utbildning som bidrar avsevärt
      till att skapa säkrare och tryggare arbetsplatser, även om dom råkar
      befinna sig på eller nära vägen.
    </blockquote>
    <figcaption style={{
          textAlign: "right"
        }}>
      &mdash; Peter Lundman, Teknikchef, affärsområde Transportation, AFRY
    </figcaption>
  </figure>
    </Section>
    <LightSection width="wider" verticalSpacing="large" mdxType="LightSection">
  <PreTitle mdxType="PreTitle">Våra utbildningar</PreTitle>
  <h2>Branschanpassade webbutbildningar</h2>
  <Row gutter={[32, 32]} align="middle" mdxType="Row">
    <Col xs={24} sm={12} mdxType="Col">
      <NextImage src="image/vagarbetare-jimman-glad.jpg" alt="Glad vägarbetare" width={520} height={4013 * (520 / 5428)} quality={10} mdxType="NextImage" />
    </Col>
    <Col xs={24} sm={12} mdxType="Col">
      <h3>Arbete på väg (APV)</h3>
      <p>Vi har flera APV-utbildningar för olika målgrupper. Allra populärast är våra kompletta paketlösningar, anpassade för antingen entreprenörer eller konsulter. Alla utbildningar uppfyller Trafikverkets senaste riktlinjer och kompetenskrav enligt både steg och nivåer.
      Om du vill registreras ditt intyg automatiskt i ID06.</p>
      <p><a href="/blogg/id06-alla-intyg-pa-ett-stalle">Läs mer om varför du bör registrera på ID06 ></a></p>
       <LargePrimaryLinkButton href="/utbildningar/grupper/arbete-pa-vag" buttonProps={{
            shape: "round"
          }} mdxType="LargePrimaryLinkButton">
        APV-utbildningar
      </LargePrimaryLinkButton>
    </Col>
    <Col xs={24} sm={12} mdxType="Col">
      <h3>ADR 1.3 – Farligt gods</h3>
      <p>Vi har två olika 1.3-utbildningar: En allmän och en specialanpassad för just bygg- och anläggningsbranschen.
      1.3-utbildning krävs för alla vars arbete rör transport av farligt gods, oavsett om du arbetar med administration,
      hantering eller själva förflyttningen av godset. Utbildningarna uppfyller MSB:s senaste krav
      och riktlinjer och om du vill registreras ditt intyg automatiskt i ID06.</p>
      <LargePrimaryLinkButton href="/utbildningar/grupper/adr" buttonProps={{
            shape: "round"
          }} mdxType="LargePrimaryLinkButton">
        ADR 1.3-utbildningar
      </LargePrimaryLinkButton>
    </Col>
    <Col xs={24} sm={12} mdxType="Col">
      <NextImage src="image/adr-pulver-flaskor.jpg" alt="Flaskor farligt gods" width={520} height={1333 * (520 / 2000)} quality={10} mdxType="NextImage" />
    </Col>
  </Row>
    </LightSection>
    <Section width="wider" verticalSpacing="large" topMargin="large" mdxType="Section">
  <PreTitle mdxType="PreTitle">Om oss</PreTitle>
  <h2>Entreprenadakademin</h2>
  <Row gutter={[32, 32]} align="middle" mdxType="Row">
    <Col xs={24} sm={12} mdxType="Col">
      <NextImage src="image/jenny-david-svartvitt-2048-1368-50.jpg" width={520} height={1368 * (520 / 2048)} quality={10} mdxType="NextImage" />
    </Col>
    <Col xs={24} sm={12} mdxType="Col">
      <h3>Vi vill stå upp för kvalité och tillgänglighet</h3>
      <p>
        Entreprenadakademin är en utbildningsportal för webbutbildningar riktade
        till entreprenadbranschen. Med våra utbildningar vill vi göra kunskapen
        mer tillgänglig och därmed ge företag ett smidigt och effektivt sätt att
        utveckla sin personal utan att göra avkall på kvalitén. Vi ser till att
        du får rätt kunskap och de intyg som krävs.
      </p>
      <p>
        <a href="/om-oss">Läs mer om Entreprenadakademin ></a>
      </p>
      <p>
        <a href="/kontakt">Kontakta oss ></a>
      </p>
    </Col>
  </Row>
    </Section>
    <LightSection width="slender" verticalSpacing="large" topMargin="large" mdxType="LightSection">
      <PreTitle mdxType="PreTitle">Från bloggen</PreTitle>
      <h2>{`ID06 - Alla utbildningsintyg på ett ställe`}</h2>
      <Spacing verticalSpacing="medium" mdxType="Spacing">
        <NextImage src="/logo/id06-2024.png" width={200} height={50} quality={10} alt="ID06 Kompetensdatabas logga" mdxType="NextImage" />
      </Spacing>
      <p>{`När du går en Arbete på väg- eller ADR-utbildning hos oss på Entreprenadakademin registreras intyget automatiskt på ditt ID06-kort. Funktionen blir allt vanligare och baseras på ett samarbete med ID06 Kompetensdatabas – ett smidigt sätt att digitalt samla alla utbildningsbevis. Det underlättar för alla inblandade och från och med i år är det dessutom ett krav för många.`}</p>
      <p>{`Enligt ett beslut från flera ledande bygg- och anläggningsföretag införs under 2021 krav på att vissa utbildningar och behörigheter finns registrerade i databasen.`}</p>
      <p>
  <a href="/blogg/id06-alla-intyg-pa-ett-stalle">
    Läs mer om hur ID06 med detta vill höja säkerheten på arbetsplatsen >
  </a>
      </p>
    </LightSection>
    <ColorSection width="slender" verticalSpacing="large" topMargin="" mdxType="ColorSection">
      <Grid gap="large" layout="twoColumnsFlexible" mdxType="Grid">
        <GridCell mdxType="GridCell">
          <h2>{`Se till att inte missa något viktigt`}</h2>
          <p>{`Följ vad som händer inom området kompetens i entreprenadbranschen.`}</p>
          <NewsletterSignupModalButton mdxType="NewsletterSignupModalButton">Anmäl mig</NewsletterSignupModalButton>
        </GridCell>
        <GridCell mdxType="GridCell">
          <NextImage src="/illustration/Ikon-olika-typer-vagarbete-entreprenor-300pxRityta 3.png" width={100} height={100} quality={10} alt="illustration grävmaskin" mdxType="NextImage" />
        </GridCell>
      </Grid>
    </ColorSection>
    <LightSection width="slender" verticalSpacing="large" topMargin="" mdxType="LightSection">
      <PreTitle mdxType="PreTitle">Följ oss</PreTitle>
      <h2>{`Håll koll på vad som händer`}</h2>
      <p>{`Följ oss i flera kanaler så håller du dig uppdaterad på vad som händer inom området
kompetens i entreprenadbranschen.`}</p>
      <p><a parentName="p" {...{
          "href": "/blogg"
        }}>{`Blogg >`}</a></p>
      <p>
  <a href="https://sv-se.facebook.com/entreprenadakademin/" native external>
    Facebook >
  </a>
      </p>
      <p>
  <a href="https://se.linkedin.com/company/entreprenadakademin" native external>
    Linkedin >
  </a>
      </p>
    </LightSection>
    <Section width="slender" topMargin="large" mdxType="Section">
      <PreTitle mdxType="PreTitle">SBSV</PreTitle>
      <h2>{`Sveriges branschförening för säkrare vägarbetsplatser`}</h2>
      <NextImage src="/logo/sbsv-logo.png" width={150} height={439 * (150 / 585)} quality={10} alt="SBSV loggga" mdxType="NextImage" />
      <p>{`SBSV är Sveriges branschförening för säkrare vägarbetsplatser. Entreprenadakademin sitter i SBSVs styrelse och är
sedan många år aktiva i föreningens arbete kring utbildning. `}</p>
      <p>
  <a href="https://sbsv.se/" native external>
    Läs mer SBSV >
  </a>
      </p>
    </Section>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;